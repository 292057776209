import { Property } from '../../types/property';

const getMainImage = (property: Property, imageLocator?: string): string[] => {
  if (imageLocator && property.images && property.images.length > 0) {
    return [property.images[0].urls[imageLocator], property.images[0].blurhash];
  }

  if (property.images && property.images.length > 0) {
    const locator = Object.keys(property.images[0].urls)[0];

    return [property.images[0].urls[locator] || property.images[0].url, property.images[0].blurhash];
  }

  if (property.image && imageLocator) {
    return [property.image.urls[imageLocator], property.image.blurhash];
  }

  if (property.image) {
    const locator = Object.keys(property.image.urls)[0];

    return [property.image.urls[locator] || property.image.url, property.image.blurhash];
  }

  return ['', ''];
};

export default getMainImage;
