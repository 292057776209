import styled from 'styled-components';

export const Container = styled.p<{ color: string }>`
  color: ${(props) => props.color};
`;

export const AltText = styled.span<{ $prefixBreak?: boolean }>`
  display: block;
  color: ${(props) => props.theme.backgroundMid};
  margin-top: 5px;

  ${({ $prefixBreak }) =>
    $prefixBreak &&
    `
    font-size: 14px;
  `}
`;

export const Prefix = styled.span<{ $prefixBreak?: boolean }>`
  ${({ $prefixBreak }) =>
    $prefixBreak &&
    `
    display: block;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: -8px;
  `}
`;

export const InBetween = styled.span<{ $prefixBreak?: boolean }>`
  ${({ $prefixBreak }) =>
    $prefixBreak &&
    `
    font-size: 14px;   
  `}
`;

export const StatusBtn = styled.span`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 15px;
  min-width: 100px;
  height: 60px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
`;
