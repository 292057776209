import { Property } from '@propertypal/shared/src/types/property';
import { getFormattedPrice } from '@propertypal/shared/src/utils/property';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { Bold } from '../typography';
import { Container, AltText, StatusBtn, Prefix, InBetween } from './PropertyPrice.style';

interface Props {
  as?: 'span' | 'p';
  containerStyle?: CSSProperties;
  price?: Property['price'];
  alternativeCurrencyPrice?: Property['alternativeCurrencyPrice'];
  fontSize: number;
  primaryColor?: string;
  secondaryColor?: string;
  status?: Property['status'];
  comingSoonText?: string;
  statusButton?: boolean;
  prefixBreak?: boolean;
}

const SOLD_STATUS = ['saleAgreed', 'sold', 'let', 'letAgreed'];

const PropertyPrice: FunctionComponent<Props> = (props) => {
  const { price, alternativeCurrencyPrice, fontSize } = props;
  const theme = useTheme();
  const isSold = !!(props.status && SOLD_STATUS.includes(props.status.key));

  if (isSold) {
    return (
      <Container
        as={props.as}
        color={props.secondaryColor || theme.primary}
        style={{ ...props.containerStyle, fontSize }}
        className="pp-property-price"
      >
        {props.statusButton && (
          <StatusBtn className="pp-property-status" data-testid="status-btn">
            {props.status?.text}
          </StatusBtn>
        )}

        {!props.statusButton && <Bold className="pp-property-price-bold">{props.status?.text}</Bold>}
      </Container>
    );
  }

  if (price && (price.priceHidden || price.priceOnApplication)) {
    return (
      <Container
        as={props.as}
        color={props.secondaryColor || theme.primary}
        style={{ ...props.containerStyle, fontSize }}
        className="pp-property-price pp-property-price-poa"
        data-testid="poaPrice"
      >
        {price.hiddenPriceText || 'POA'}
      </Container>
    );
  }

  if (price && price.minPrice && price.minPrice !== price.maxPrice) {
    const minPrice = getFormattedPrice(price.minPrice, price.currencySymbol);
    const maxPrice = getFormattedPrice(price.maxPrice, price.currencySymbol);

    const altMinPrice = alternativeCurrencyPrice
      ? getFormattedPrice(alternativeCurrencyPrice.minPrice, alternativeCurrencyPrice.currencySymbol)
      : '';
    const altMaxPrice = alternativeCurrencyPrice
      ? getFormattedPrice(alternativeCurrencyPrice.maxPrice, alternativeCurrencyPrice.currencySymbol)
      : '';

    return (
      <Container
        as={props.as}
        color={props.secondaryColor || theme.textLight}
        style={{ ...props.containerStyle, fontSize }}
        className="pp-property-price"
      >
        <span>
          <Prefix $prefixBreak={props.prefixBreak}>{`From `}</Prefix>
          <Bold color={props.primaryColor || theme.primary} data-testid="minPrice" className="pp-property-price-bold">
            {minPrice}
          </Bold>
          <InBetween $prefixBreak={props.prefixBreak}>{` to `}</InBetween>
          <Bold color={props.primaryColor || theme.primary} data-testid="maxPrice" className="pp-property-price-bold">
            {maxPrice}
          </Bold>
        </span>

        {!!altMinPrice && !!altMaxPrice && (
          <AltText data-testid="altCurrencyText">
            {`(Est.From `}
            <Bold color={theme.primary} data-testid="altMinPrice" className="pp-property-price-bold">
              {altMinPrice}
            </Bold>
            <InBetween $prefixBreak={props.prefixBreak}>{` to `}</InBetween>
            <Bold color={theme.primary} data-testid="altMaxPrice" className="pp-property-price-bold">
              {altMaxPrice}
            </Bold>
            )
          </AltText>
        )}
      </Container>
    );
  }

  if (price && price.price) {
    const formattedPrice = getFormattedPrice(price.price, price.currencySymbol);
    const altPrice = alternativeCurrencyPrice
      ? getFormattedPrice(alternativeCurrencyPrice.price, alternativeCurrencyPrice.currencySymbol)
      : '';

    return (
      <Container
        as={props.as}
        color={props.secondaryColor || theme.textLight}
        style={{ ...props.containerStyle, fontSize }}
        className="pp-property-price"
      >
        <span>
          {price.pricePrefix && <Prefix $prefixBreak={props.prefixBreak}>{`${price.pricePrefix} `}</Prefix>}
          <Bold color={props.primaryColor || theme.primary} className="pp-property-price-bold">
            {formattedPrice}
          </Bold>
          {price.priceSuffix && <Prefix $prefixBreak={props.prefixBreak}>{` ${price.priceSuffix}`}</Prefix>}
        </span>

        {!!alternativeCurrencyPrice && !!altPrice && (
          <AltText data-testid="altCurrencyText" $prefixBreak={props.prefixBreak}>
            (Est.{alternativeCurrencyPrice.pricePrefix && ` ${alternativeCurrencyPrice.pricePrefix} `}
            <Bold color={props.primaryColor || theme.primary} className="pp-property-price-bold">
              {altPrice}
            </Bold>
            {alternativeCurrencyPrice.priceSuffix && ` ${alternativeCurrencyPrice.priceSuffix}`})
          </AltText>
        )}
      </Container>
    );
  }

  if (props.comingSoonText) {
    return (
      <Container
        as={props.as}
        color={props.secondaryColor || theme.primary}
        style={{ ...props.containerStyle, fontSize }}
        className="pp-property-price"
      >
        <Bold className="pp-property-price-bold">{props.comingSoonText}</Bold>
      </Container>
    );
  }

  return null;
};

export default PropertyPrice;
