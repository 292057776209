import bera1 from '@propertypal/shared/src/resources/icons/ber/bera1@3x.png';
import bera2 from '@propertypal/shared/src/resources/icons/ber/bera2@3x.png';
import bera3 from '@propertypal/shared/src/resources/icons/ber/bera3@3x.png';
import berb1 from '@propertypal/shared/src/resources/icons/ber/berb1@3x.png';
import berb2 from '@propertypal/shared/src/resources/icons/ber/berb2@3x.png';
import berb3 from '@propertypal/shared/src/resources/icons/ber/berb3@3x.png';
import berc1 from '@propertypal/shared/src/resources/icons/ber/berc1@3x.png';
import berc2 from '@propertypal/shared/src/resources/icons/ber/berc2@3x.png';
import berc3 from '@propertypal/shared/src/resources/icons/ber/berc3@3x.png';
import berd1 from '@propertypal/shared/src/resources/icons/ber/berd1@3x.png';
import berd2 from '@propertypal/shared/src/resources/icons/ber/berd2@3x.png';
import bere1 from '@propertypal/shared/src/resources/icons/ber/bere1@3x.png';
import bere2 from '@propertypal/shared/src/resources/icons/ber/bere2@3x.png';
import berexempt from '@propertypal/shared/src/resources/icons/ber/berexempt@3x.png';
import berf from '@propertypal/shared/src/resources/icons/ber/berf@3x.png';
import berg from '@propertypal/shared/src/resources/icons/ber/berg@3x.png';
import { Property } from '@propertypal/shared/src/types/property';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { Text } from '../typography';
import { Container, EpcIcon } from './EnergyRatingIcon.style';

interface Props {
  containerStyle?: CSSProperties;
  property: Property;
  hideEpcText?: boolean;
}

const ICON_MAP: { [key: string]: any } = {
  A1: bera1,
  A2: bera2,
  A3: bera3,
  B1: berb1,
  B2: berb2,
  B3: berb3,
  C1: berc1,
  C2: berc2,
  C3: berc3,
  D1: berd1,
  D2: berd2,
  E1: bere1,
  E2: bere2,
  F: berf,
  G: berg,
};

const EnergyRatingIcon: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  if (props.property.epc) {
    return (
      <Container style={props.containerStyle}>
        <EpcIcon data-testid="epcIcon">
          <Text
            className="pp-epc-icon-text"
            color={theme.white}
            fontSize={10}
            aria-label={`EPC Rating ${props.property.epc.ratingShorthand}`}
          >
            {props.property.epc.ratingShorthand}
          </Text>
        </EpcIcon>

        {!props.hideEpcText && <Text>EPC Rating</Text>}
      </Container>
    );
  }

  if (props.property.berExempt) {
    return (
      <Container style={props.containerStyle}>
        <img src={berexempt.src} data-testid="berExemptIcon" alt="BER Exempt" style={{ width: 100, height: 22 }} />
      </Container>
    );
  }

  if (
    props.property.ber &&
    props.property.ber.alphanumericRating &&
    ICON_MAP[props.property.ber.alphanumericRating.toUpperCase()]
  ) {
    return (
      <Container style={props.containerStyle}>
        <img
          src={ICON_MAP[props.property.ber.alphanumericRating.toUpperCase()].src}
          data-testid="berIcon"
          alt={`BER rating ${props.property.ber.alphanumericRating}`}
          style={{ width: 62, height: 22 }}
        />
      </Container>
    );
  }

  return null;
};

export default EnergyRatingIcon;
