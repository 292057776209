const numberWithCommas = /\B(?=(\d{3})+(?!\d))/g;

const getFormattedPrice = (price?: number | string, symbol?: string) => {
  if (price === undefined) {
    return '';
  }

  const formattedNumbers = price.toString().replace(numberWithCommas, ',');
  return `${symbol}${formattedNumbers}`;
};

export default getFormattedPrice;
