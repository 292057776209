import { AgentLogoType, Property } from '../../types/property';

/*
 * Returns agents logos urls in array
 */

const getAgentLogos = (property: Property, type: 'ALTERNATIVE' | 'PREMIER' | 'STANDARD'): AgentLogoType[] => {
  if (property.agentLogos) {
    const logos = property.agentLogos.filter((l) => l.logoType === type);

    return logos.slice(0, 3);
  }

  return [];
};

export default getAgentLogos;
