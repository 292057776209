/* eslint-env browser */
import { Property, TourType } from '../../types/property';

export interface GalleryItem {
  type: 'webview' | 'image' | 'video' | 'noimage' | 'sitemap';
  tourType?: TourType;
  image: string;
  link?: string;
  text?: string;
  width: number;
  height: number;
  displayOrder: number;
  insecure?: boolean;
  index: number;
  key: string;
  tourIndex?: number;
}

export interface GalleryItemChunks {
  keyItem: string;
  images: GalleryItem[];
}

const toursTextMap: { [key: string]: string } = {
  '360-tour': 'here to view the 360 tour',
  floorplan: 'here to view the floor plan',
  audio: 'here to listen to the audio tour',
  video: 'here to view the video',
  '3d-tour': 'here to view the 3D tour',
  book: 'here to view the brochure tour',
  generic: 'here to view the tour',
  'virtual-tour': 'here to view virtual tour',
};

export const getSiteMaps = (property: Property, imageLocator: string) => {
  const images: GalleryItem[] =
    property.images && property.images.length
      ? property.images
          .filter((image) => image.imageType === 'SiteMap')
          .map((image, index) => ({
            type: image.imageType === 'SiteMap' ? 'sitemap' : 'image',
            image: image.urls[imageLocator] || image.url,
            width: image.width,
            height: image.height,
            displayOrder: image.displayOrder,
            key: image.url,
            index,
          }))
      : [];

  return images;
};

const getGalleryImages = (
  property: Property,
  imageLocator: string,
  includeTours?: boolean,
  includeVideos?: boolean,
  includeSitemap?: boolean,
  includeFloorPlans?: boolean,
): GalleryItem[] => {
  const prefix = typeof navigator !== 'undefined' && navigator.product === 'ReactNative' ? 'Tap' : 'Click';

  const images: GalleryItem[] =
    property.images && property.images.length
      ? property.images
          .filter(
            (image) =>
              !(
                (!includeSitemap && image.imageType === 'SiteMap') ||
                (!includeFloorPlans && image.imageType === 'FloorPlan')
              ),
          )
          .map((image, index) => ({
            type: image.imageType === 'SiteMap' ? 'sitemap' : 'image',
            image: image.urls[imageLocator] || image.url,
            width: image.width,
            height: image.height,
            displayOrder: image.displayOrder,
            key: image.url,
            index,
          }))
      : [];

  if (property.image && images.length === 0) {
    images.push({
      type: property.image.imageType === 'SiteMap' ? 'sitemap' : 'image',
      image: property.image.urls[imageLocator] || property.image.url,
      width: property.image.width,
      height: property.image.height,
      displayOrder: property.image.displayOrder,
      key: property.image.url,
      index: 0,
    });
  }

  if (images.length === 0) return images;

  let indexes = images.length - 1;

  const tours: GalleryItem[] =
    property['360Tours'] && includeTours
      ? property['360Tours'].map((tour, index) => {
          indexes += 1;
          return {
            type: 'webview',
            tourType: tour.type,
            image: images[0].image,
            link: tour.url,
            text: `${prefix} ${toursTextMap[tour.type]}`,
            displayOrder: images[0].displayOrder,
            width: images[0].width,
            height: images[0].height,
            key: tour.url,
            insecure: tour.insecure,
            tourIndex: index + 1,
            index: indexes,
          };
        })
      : [];

  indexes += 1;
  const videos: GalleryItem[] =
    property.videos?.length && includeVideos
      ? [
          {
            type: 'video',
            image: images[0].image,
            link: property.videos[0].url,
            text: `${prefix} here to view the video`,
            displayOrder: property.videos[0].displayOrder,
            width: images[0].width,
            height: images[0].height,
            key: property.videos[0].url,
            index: indexes,
          },
        ]
      : [];

  return [...images, ...tours, ...videos];
};

export default getGalleryImages;
