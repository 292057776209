import epc from '@propertypal/shared/src/resources/icons/epc/epc@3x.png';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EpcIcon = styled.div`
  background-image: url('${epc.src}');
  background-size: contain;
  width: 70px;
  height: 22px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
