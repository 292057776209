const getObjectIdForBuyerTypes = (buyerType: string): number => {
  switch (buyerType) {
    case 'FIRST_TIME_BUYER':
      return 1;
    case 'HOME_MOVER':
      return 2;
    case 'BUY_TO_LET_INVESTOR':
      return 3;
    case 'ADDITIONAL_HOME_BUYER':
      return 4;
    default:
      return 1;
  }
};

export default getObjectIdForBuyerTypes;
